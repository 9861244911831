import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Spacer from "../components/spacer"

import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import TagStrip from "../components/tag-strip"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const dashify = (string) => {
    return string.replace(/\s+/g, '-').toLowerCase();
  }

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteAuthor = data.site.siteMetadata.author

  const { previous, next } = pageContext
  const { tags } = post.frontmatter

  const author = post.frontmatter.author || siteAuthor.name || post.fields.slug
  const image = require(`../../content/authors/${dashify(author)}/image.png`)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >

            {post.frontmatter.date}
            <Spacer />
            <img style={{ marginTop: "auto", marginBottom: "auto", transform: "translateY(-1.5px)", objectFit: "cover", borderRadius: "100%", verticalAlign: "middle", width: 20, height: 20 }} src={image} /> <Spacer x={3} inline /><b>{author}</b>
            <Spacer y={5} />

            <TagStrip tags={tags} style={{}} />

          </p>

        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio authorName={post.frontmatter.author} />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        tags
      }
    }
  }
`

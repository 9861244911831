/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const dashify = (string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
}

const Bio = props => {
   const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author {
              name
            }
            description
          }
        }
      }
    `
  )

  const name = dashify(props.authorName || site.siteMetadata.author.name)
  const author = require(`../../content/authors/${name}/bio`)
  const image = require(`../../content/authors/${name}/image.png`)

  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <img
        src={image}
        style={{
          borderRadius: "100%",
          width: 50,
          height: 50,
          marginRight: 10,
          marginTop: 5,
        }}
      />
      <p style={{ width: "100%" }}>
        <p style={{ fontWeight: "bold", margin: 0 }}>{author.name}</p>
        <p style={{ margin: 0 }}>{author.bio}</p>
      </p>
    </div>
  )
}

export default Bio
